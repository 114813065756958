import { render, staticRenderFns } from "./Column.vue?vue&type=template&id=01ec265b&scoped=true"
import script from "./Column.vue?vue&type=script&lang=js"
export * from "./Column.vue?vue&type=script&lang=js"
import style0 from "./Column.vue?vue&type=style&index=0&id=01ec265b&prod&lang=scss"
import style1 from "./Column.vue?vue&type=style&index=1&id=01ec265b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01ec265b",
  null
  
)

export default component.exports