import eventBus from '@/utils/eventBus'
import { mapState, mapGetters } from 'vuex'
export default {
    props: {
        isFull: {
            type: Boolean,
            default: false
        },
        item: {
            type: Object,
            required: true
        },
        model: {
            type: String,
            default: 'tasks.TaskModel'
        },
        dropTrigger: {
            type: Array,
            default: () => ['click']
        },
        editFull: {
            type: Function,
            default: () => {}
        },
        copyFunc: {
            type: Function,
            default: () => {}
        },
        addSubtaskFunc: {
            type: Function,
            default: () => {}
        },
        addTaskFunc: {
            type: Function,
            default: () => {}
        },
        deleteFunc: {
            type: Function,
            default: () => {}
        },
        getPopupContainer: {
            type: Function,
            default: () => document.body
        },
        showStatus: {
            type: Boolean,
            default: true
        },
        showButton: {
            type: Boolean,
            default: true
        },
        closeDrawer: {
            type: Function,
            default: () => {}
        },
        pageName: {
            type: String,
            default: ''
        }
    },
    computed: {
        ...mapState({
            user: state => state.user.user,
            statusList: state => state.task.statusList,
            statusLoader: state => state.task.statusLoader
        }),
        ...mapGetters({
            taskActions: 'task/taskActions'
        }),
        dropActions() {
            const actions = this.taskActions(this.item.task_type, this.item.id)
            if(actions)
                return actions.actions
            return null
        },
        filteredList() {
            if(this.statusList?.[this.item.task_type]?.length)
                return this.statusList[this.item.task_type]
            return []
        },
        myTask() {
            if (!this.user?.id) { return }
            return [
                this.item.owner?.id,
                this.item.workgroup?.author,
                this.item.sprint?.author,
                this.item.project?.author,
            ].includes(this.user.id)
        },
        isAuthor() {
            if (!this.user?.id) { return }
            return [
                this.item.owner?.id,
                this.item.workgroup?.author,
                this.item.sprint?.author,
                this.item.project?.author,
            ].includes(this.user.id)
        },
        isOperator() {
            return this.user?.id === this.item.operator?.id
        },
        isLogistic() {
            return this.item?.task_type === 'logistic'
        }
    },
    data() {
        return{
            loading: false,
            actionLoading: false
        }
    },
    methods: {
        addToSprint() {
            eventBus.$emit('task_add_sprint', this.item)
        },
        addToMyWorkPlan() {
            eventBus.$emit('add_task_in_my_work_plan', this.item)
        },
        async removeToSprint() {
            try {
                this.loading = true
                const { data } = await this.$http.put(`tasks/task/${this.item.id}/set_sprint/`, {
                    sprint: null
                })
                if(data) {
                    if(this.isFull)
                        await this.getTaskActions()
                    this.$message.success("Задача удалена из спринта")
                    eventBus.$emit('update_sprint_detail')
                    eventBus.$emit('sprint_update_table_reload')
                }
            } catch(error) {
                console.log(error)
                if(error && Array.isArray(error)) {
                    this.$message.error(error.join(', '))
                } else {
                    this.$message.error('Ошибка')
                }
            } finally {
                this.loading = false
            }
        },
        checkRole(actions) {
            const all = actions.roles.find(f => f === 'all')

            if(all) { return true }
            const operator = actions.roles.find(f => f === 'operator')
            if(operator)
                return this.myTask

            const owner = actions.roles.find(f => f === 'owner')
            if(owner)
                return this.isAuthor
        },
        visibleChange(visible) {
            if(visible) {
                this.getTaskActions()
            } else {
                this.clearActions()
            }
        },
        clearActions() {
            this.$store.commit('task/CLEAR_TASK_ACTIONS', {
                task_type: this.item.task_type,
                id: this.item.id
            })
        },
        async getTaskActions() {
            try {
                this.actionLoading = true
                await this.$store.dispatch('task/getTaskActions', {
                    task_type: this.item.task_type,
                    id: this.item.id
                })
            } catch(e) {
                this.$message.error(this.$t('error'))
            } finally {
                this.actionLoading = false
            }
        },
        changeStatus(status) {
            const cooperator = this.item.cooperators.find(cooperator => cooperator.user.id === this.user.id)
            if (cooperator && !(this.isAuthor || this.isOperator)) {
                return this.changeCooperatorStatus(status, cooperator.id)
            }
            return this.changeTaskStatus(status)
        },
        async changeTaskStatus(status) {
            try {
                this.loading = true
                const data = await this.$store.dispatch('task/changeStatus', {
                    task: this.item, 
                    status
                })
                const pageName = this.pageName || this.$store.state.task.pageName
                eventBus.$emit(`table_row_${pageName}`, {
                    action: 'update',
                    row: data
                })
            } catch(e) {
                this.$message.error(this.$t('error'))
            } finally {
                this.loading = false
            }
        },
        changeCooperatorStatus(status, cooperatorId) {
            this.loading = true
            const payload = {
                id: cooperatorId,
                status: status.code
            }
            const url = `tasks/task/${this.item.id}/cooperator_status/`
            this.$http.put(url, payload)
                .then(({ data }) => {
                    if (this.$store.state.task.task) {
                        const cooperator = this.$store.state.task.task.cooperators.find(cooperator => cooperator.id === data.id)
                        cooperator.status.code = data.status
                    }
                })
                .catch(error => {
                    this.$message.error('Не удалось изменить статус соисполнителя')
                    console.error(error)
                })
                .finally(() => {
                    this.loading = false
                })

        },
        share() {
            const shareParams = {
                model: this.model,
                shareId: this.item.id,
                object: this.item,
                shareUrl: `${window.location.origin}/?task=${this.item.id}`,
                shareTitle: `${this.$t(`task.${this.item.task_type}`)} - ${this.item.name}`,
            }
            try {
                this.$store.commit('share/SET_SHARE_PARAMS', shareParams)
            } catch(error) {
                this.$message.error(this.$t('task.file_share_error'))
                console.error(error)
            }
        
        },
        async deleteTask() {
            try {
                this.loading = true
                const res = await this.$store.dispatch('task/deleteTask', this.item)
        
                if(res) {
                    if (this.item.project) {
                        this.$store.commit('projects/DELETE_TABLE_ROW', { record: this.item, tableKey: 'project_tasks' })
                    }

                    this.$message.success(this.$t('task.task_deleted'))
                    const pageName = this.pageName || this.$store.state.task.pageName
                    eventBus.$emit(`table_row_${pageName}`, {
                        action: 'delete',
                        row: this.item
                    })
                }
            } catch(e) {
                this.$message.error(this.$t('error') + e)
                console.log(e)
            } finally {
                this.loading = false
            }
        },
        async edit() {
            try {
                this.loading = true
                const res = await this.$store.dispatch('task/getFullTask', this.item.id)
                this.$store.commit('task/SET_PAGE_NAME', {
                    pageName: this.pageName
                })
                if(res)
                    eventBus.$emit('EDIT_TASK', {
                        back: false,
                        task_type: this.item.task_type || 'task'
                    })
            } catch(e) {
                this.$message.error(this.$t('error'))
            } finally {
                this.loading = false
            }
        },
        addSubtask() {
            eventBus.$emit('ADD_WATCH', {type: 'subtask', data: this.item})
        },
        async copy() {
            try {
                this.loading = true
                const res = await this.$store.dispatch('task/getFullTask', this.item.id)
                if(res)
                    eventBus.$emit('ADD_WATCH', {type: 'copy', data: res}) 
            } catch(e) {
                this.$message.error(this.$t('error'))
            } finally {
                this.loading = false
            }
        }
    },
    mounted() {
        eventBus.$on(`task_update_actions_${this.item.id}`, () => {
            this.getTaskActions()
        })
    },
    beforeDestroy() {
        eventBus.$off(`task_update_actions_${this.item.id}`)
    }
}