export default () => ({
    pageName: null,
    editDrawer: false,
    formDefault: null,
    formInfo: {},
    taskTypeActiveTab: {},
    taskCalendar: [],
    taskCalendarEmpty: [],
    taskList: {},
    taskCount: {},
    tableEmpty: {},
    task: null,
    next: {},
    mainKey: null,
    taskShow: false,
    emdedTaskShow: false,
    sprintShow: false,
    hardZIndex: null,
    taskDrawerZIndex: 1000,
    sprintDrawerZIndex: 1000,
    statusList: {},
    statusLoader: false,
    taskTable: {},
    workTimeSettings: {},
    taskActions: {},
    taskEmptyNext: true,
    universalTabs: {},
    universalTabsForm: {},
    universalTabsList: {},
    taskAsideStat: {},
    taskDrawerOptions: null,
    gantActiveFilter: {},
    taskType: 'task',
    taskPages: {},
    taskListLoading: {},
    taskPointsList: [],
    pointFormData: {
        name: '',
        lat: null,
        lon: null,
        address: ''
    },
    markedPoint: {
        lat: null,
        lng: null,
    },
    searchMarker: {
        lat: null,
        lon: null,
        toolTipText: ''
    },
    markersList: [],
    mapConfig: {},
    leadSources: [],
    leadSourcesLoader: false,
    rejectionReasonList: [],
    rejectionReasonListLoader: false,
    mobileViewType: {},
    mobileSlideIndex: {},
    defaultMobileViewType: 'list',
    sprintAddTaskShow: false
})