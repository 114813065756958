<template>
    <a-drawer
        ref="drawerTemplate"
        :visible="value"
        :zIndex="zIndex"
        title=""
        :placement="placement"
        class="drawer_wrap"
        :width="drawerWidth"
        :maskClosable="maskClosable"
        :mask="mask"
        :push="drawerPush"
        :destroyOnClose="destroyOnClose"
        :forceRender="forceRender"
        :afterVisibleChange="afterVisibleChange"
        @close="handlerClose">
        <div class="drawer_header">
            <div class="w-full truncate flex items-center">
                <template v-if="loading">
                    <a-skeleton-button 
                        active 
                        size="small" 
                        block />
                </template>
                <template v-else>
                    <template v-if="$slots.title">
                        <!-- Если нужно кастомизировать заголовок драйвера вместо title используем слот #title -->
                        <slot name="title" />
                    </template>
                    <div v-else class="title truncate">
                        {{ title }}
                    </div>
                </template>
            </div>
            <!-- <div class="flex items-center pl-2">
                <div v-if="$slots.header || $slots.actions || edit || openA || share || deleteA" class="mr-2 flex items-center">
                    Шапка драйвера (Правая часть)
                    <slot v-if="$slots.header" name="header" />
                    <Actions 
                        v-if="$slots.actions || edit || openA || share || deleteA" 
                        :edit="edit" 
                        :deleteA="deleteA"
                        :deleteContent="deleteContent"
                        :deleteHandler="deleteHandler"
                        :shareHandler="shareHandler"
                        :open="openA"
                        :openHandler="openHandler"
                        :share="share"
                        :editHandler="editHandler" 
                        :buttonType="actionsButtonType"
                        rootClass="ml-2">
                        <template #menu>
                            <slot name="actions" />
                        </template>
                    </Actions>
                </div>
                <a-button type="text" shape="circle" @click="handlerClose()">
                    <template #icon>
                        <i class="fi fi-rr-cross"></i>
                    </template>
                </a-button>
            </div> -->
        </div>
        <div 
            v-if="useDrawerTabs && drawerTabsList.length" 
            class="drawer_menu select-none">
            <!-- 
                useDrawerTabs - Активирует табы в драйвере
                drawerTabs - Передаем массив табов в драйвер
            -->
            <div v-if="loading" class="h-full flex items-center" style="padding-left: 15px;padding-right: 15px;">
                <a-skeleton-button 
                    active 
                    size="small" 
                    block />
            </div>
            <a-config-provider 
                v-else >
                <a-menu 
                    v-model="currentMenu" 
                    mode="horizontal" 
                    @click="menuClick">
                    <a-menu-item v-for="tab in drawerTabsList" :key="tab.key">
                        {{ tab.title }}
                    </a-menu-item>
                </a-menu>
            </a-config-provider>
        </div>
        <div 
            ref="bodyRef"
            class="drawer_body" 
            :class="[!disabledBodyPadding && 'padding', tabFill && 'body_fill', xHidden && 'overflow-x-hidden']">
            <template v-if="$slots.aside">
                <a-row :gutter="rowGutter" class="drawer_row">
                    <a-col 
                        :sm="24" 
                        class="drawer_col"
                        :lg="showTabAside ? 16 : 24">
                        <template v-if="loading">
                            <a-skeleton 
                                active 
                                :paragraph="{rows: 6}"  />
                        </template>
                        <template v-else>
                            <!-- Содержимое драйвера -->
                            <template v-if="useDrawerTabs && drawerTabsList.length">
                                <template v-for="menu in drawerTabsList">
                                    <slot 
                                        v-if="currentMenuActive === menu.key"
                                        :name="`body_${menu.key}`" />
                                </template>
                            </template>
                            <slot v-else name="default" />
                        </template>
                    </a-col>
                    <a-col 
                        v-if="showTabAside"
                        :sm="24"
                        class="drawer_col"
                        :lg="8">
                        <div class="drawer_aside">
                            <template v-if="loading">
                                <a-skeleton 
                                    active 
                                    :paragraph="{rows: 6}"  />
                            </template>
                            <slot v-else name="aside" />
                        </div>
                    </a-col>
                </a-row>
            </template>
            <template v-else>
                <template v-if="loading">
                    <a-skeleton 
                        active 
                        :paragraph="{rows: 6}"  />
                </template>
                <template v-else>
                    <!-- Содержимое драйвера -->
                    <template v-if="useDrawerTabs && drawerTabsList.length">
                        <template v-for="menu in drawerTabsList">
                            <slot 
                                v-if="currentMenuActive === menu.key"
                                :name="`body_${menu.key}`" />
                        </template>
                    </template>
                    <slot v-else name="default" />
                </template>
            </template>
        </div>
        <div 
            v-if="$slots.footer" 
            class="drawer_footer">
            <!-- Подвал драйвера -->
            <template v-if="loading">
                <a-skeleton-button 
                    active 
                    size="small" 
                    block />
            </template>
            <a-config-provider v-else>
                <slot name="footer" />
            </a-config-provider>
        </div>
    </a-drawer>
</template>

<script>
// import { useStore } from 'vuex'
// import Actions from '@/components/Actions/index.vue'
// import { closeRouteDrawer, openRoute, useCurrentRoute } from '@/utils/routersUtils.js'
// import { useThemeStore } from '@/stores/theme.js'
// import { buttonType } from '@model/index.js'
import { v1 as uuidv1 } from 'uuid'

export default {
    props: {
        value: { // v-model на visible
            type: Boolean,
            required: true
        },
        hardZIndex: {
            type: Number,
            default: null,
        },
        visibleChange: {
            type: Function,
            default: () => {}
        },
        title: { // Заголовок драйвера
            type: [String, Number],
            default: ''
        },
        placement: { // Положение драйвера
            type: String,
            default: 'right'
        },
        width: { // Ширина драйвера (Автоматический подстраиваеться если ширина меньше указанной)
            type: [Number, String],
            default: 400
        },
        loading: { // Передаем комманду для показа предзагрузчика в контентной части драйвера
            type: Boolean,
            default: false
        },
        disabledBodyPadding: { // Выключает падинги в контентной части драйвера
            type: Boolean,
            default: false
        },
        share: { // Показать кнопку поделиться в меню
            type: Boolean,
            default: false
        },
        edit: { // Показать кнопку редактирования в меню
            type: Boolean,
            default: false
        },
        deleteA: { // Показать кнопку удаления в меню
            type: Boolean,
            default: false
        },
        open: { // Показать кнопку открытия в меню
            type: Boolean,
            default: false
        },
        shareHandler: { // функция для кнопки поделиться
            type: Function,
            default: () => {}
        },
        editHandler: { // функция для кнопки редактирования
            type: Function,
            default: () => {}
        },
        deleteHandler: { // функция для кнопки удаления
            type: Function,
            default: () => {}
        },
        openHandler: { // функция для кнопки открытия
            type: Function,
            default: () => {}
        },
        deleteContent: { // какой текст будет показан в модалке для удаления
            type: [String, Number],
            default: 'Вы действительно хотите удалить элемент?'
        },
        mask: { // Включить/Выключить маску драйвера
            type: Boolean,
            default: true
        },
        maskClosable: { // Сделать маску кликабельной для закрытия драйвера
            type: Boolean,
            default: true
        },
        forceRender: { // Рендерить драйвер всегда
            type: Boolean,
            default: true
        },
        destroyOnClose: { // удалить из DOM при закрытии
            type: Boolean,
            default: false
        },
        drawerTabs: { // Тут передаем массив табов по первому примеру https://antdv.com/components/menu, children так же работает для выпадающего меню
            type: Array,
            default: () => []
        },
        useDrawerTabs: { // Если хотим активировать табы передаем true
            type: Boolean,
            default: false
        },
        push: {
            type: [Number, Boolean],
            default: 180
        },
        rowGutter: {
            type: Number,
            default: 20
        },
        actionsButtonType: {
            type: String,
            default: 'drawer',
            validator(value, props) {
                return ['full', 'default', 'drawer'].includes(value)
            }
        },
        xHidden: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            drawerTemplate: null,
            drawerUid: uuidv1(),
            currentMenu: [],
        }
    },
    watch: {
        drawerTabsList(tbList) {
            if(tbList.length && !this.currentMenu.length)
                this.currentMenu = [this.drawerTabsList[0].key]
        }
    },
    computed: {
        zIndex() {
            if (this.hardZIndex) { return this.hardZIndex }

            const openDrawers = this.$store.state.openDrawers
            const currentDrawer = openDrawers.find(drawer => drawer.uid === this.drawerUid)
            return currentDrawer?.zIndex || openDrawers?.[openDrawers.length-1]?.zIndex + 100 || 1000
        },
        windowWidth() { 
            return this.$store.state.windowWidth
        },
        currentMenuActive() { 
            return this.currentMenu?.[0] || null 
        },
        showTabAside() {
            const find = this.drawerTabsList.find(f => f.key === this.currentMenuActive)
            if(find)
                return find.showAside
            return true
        },
        tabFill() {
            const find = this.drawerTabsList.find(f => f.key === this.currentMenuActive)
            if(find)
                return find.tabFill
            return false
        },
        drawerWidth() {
            return this.windowWidth < this.width ? '100%' : this.width
        },
        drawerPush() {
            if(this.isMobile)
                return false
            return {
                distance: this.push
            }
        },
        isMobile() { 
            return this.$store.state.isMobile
        },
        drawerTabsList() {
            return this.drawerTabs.map(tab => {
                return {
                    ...tab,
                    showAside: typeof tab.showAside === 'boolean' ? tab.showAside : true,
                    tabFill: typeof tab.tabFill === 'boolean' ? tab.tabFill : false,
                }
            }) || []
        }
    },
    methods: {
        getZIndex() {
            return this.zIndex
        },
        closeDrawer ()  {
            if(this.useDrawerTabs) {
                this.currentMenu = []
            }
        },
        
        menuClick ({key, keyPath}) {
            this.currentMenu = keyPath
            console.log(keyPath, this.currentMenu)
            
            // Передаем события клика по табу драйвера с ключем активного таба
            this.$emit('tabChange', key)
        },
        
        afterVisibleChange (visible)  {
            if(visible) {
                this.$store.commit('PUSH_OPEN_DRAWERS', this.drawerUid)
            } else {
                this.closeDrawer()
                this.$store.commit('REMOVE_OPEN_DRAWERS', this.drawerUid)
            }

            this.$emit('afterVisibleChange', visible)
        },
        
        // Функция закрытия драйвера, в дочернем компоненте вызываеться через ref: drawerForm.handlerClose()
        handlerClose () {
            this.$emit('close')
            // this.$emit('input', false)
        },
        openDrawer () {
            this.$emit('input', true)
        }
    },
}
</script>



<!-- <template>
    <a-drawer
        :visible="value"
        :width="width"
        :zIndex="zIndex"
        :afterVisibleChange="afterVisibleChange"
        @close="close">
        <slot name="default"></slot>
    </a-drawer>
</template>

<script>
import { v1 as uuidv1 } from 'uuid'

export default {
    props: {
        // v-model for visible
        value: {
            type: Boolean,
            requred: true
        },
        visibleChange: {
            type: Function,
            default: () => {}
        },
        width: {
            type: [Number, String],
            default: 400
        }
    },
    data() {
        return {
            visible: false,
            drawerId: uuidv1()
        }
    },
    computed: {
        zIndex() {
            return this.$store.state.drawerQueue?.[this.drawerId]?.zIndex
        }
    },
    methods: {
        close() {
            this.$emit('input', false)
            this.$emit('close')
        },
        open() {
            this.$store.commit('PUSH_DRAWER_TO_QUEUE')
        },
        async afterVisibleChange(visible) {
            if (visible) {
                console.log('opendrawer')
                this.$store.commit('PUSH_DRAWER_TO_QUEUE', { drawerId: this.drawerId })
            // pass
            } else {
                this.$store.commit('POP_DRAWER_TO_QUEUE', { drawerId: this.drawerId })
            }
            await this.visibleChange(visible)
        }
    }
}
</script> -->

<style lang="scss" scoped>
.drawer_menu{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    position: relative;
    z-index: 10;
    &.use_shadow{
        &:deep() {
            .ant-menu{
                box-shadow: 0 8px 15px rgba(0, 0, 0, 0.04);
            }
        }
    }
    &:deep() {
        .ant-menu{
            border-radius: 16px;
            padding: 11px 20px;
            transition: width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s, box-shadow 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
            &.ant-menu-light{
                background: var(--bgWrap);
            }
            &.ant-menu-horizontal{
                border: 0px;
            }
            .ant-menu-item{
                border-radius: 30px;
                &:not(:first-child){
                    margin-left: 10px;
                }
                &.ant-menu-item-active,
                &.ant-menu-item-selected{
                    &::after{
                        display: none;
                    }
                }
                &.ant-menu-item-selected{
                    background: var(--primary2);
                }
            }
        }
    }
}
.drawer_aside{
    padding: 20px 0;
    background: var(--bgWrap);
    border-radius: var(--borderRadius2);
}
</style>

<style lang="scss">
.drawer_wrap{
    &.ant-drawer{
        .ant-drawer-header{
            display: none;
        }
        .ant-drawer-content-wrapper{
            box-shadow: initial;
        }
        .ant-drawer-content{
            overflow: hidden;
            // border-radius: 32px 0 0 32px;
        }
        .ant-drawer-body{
            height: 100%;
            overflow: hidden;
            padding: 0px;
            display: flex;
            flex-direction: column;
            .drawer_footer{
                display: flex;
                align-items: center;
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 20px;
                padding-top: 10px;
            }
            .drawer_header{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 30px;
                padding-left: 30px;
                padding-right: 30px;
                padding-bottom: 10px;
                .title{
                    font-size: 24px;
                }
            }
            .drawer_body{
                overflow-y: auto;
                flex-grow: 1;
                &.body_fill{
                    overflow: hidden;
                }
                &.padding{
                    padding: 20px 30px;
                }
                .drawer_col{
                    position: initial;
                }
                .drawer_row{
                    height: 100%;
                }
            }
        }
    }
}
</style>
