<template>
    <a-dropdown 
        :trigger="dropTrigger"
        :destroyPopupOnHide="true"
        @visibleChange="visibleChange">
        <a-button 
            :loading="loading" 
            icon="menu" 
            type="link" />
        <a-menu slot="overlay">
            <a-menu-item 
                v-if="!dropActions && actionLoading"
                key="menu_loader"
                class="flex justify-center">
                <a-spin size="small" />
            </a-menu-item>
            <template v-if="dropActions">
                <template v-if="changeStatusVisible">
                    <a-menu-item 
                        v-if="statusLoader"
                        key="loader"
                        class="flex justify-center">
                        <a-spin size="small" />
                    </a-menu-item>
                    <template v-else>
                        <a-menu-item 
                            v-for="status in cStatusFiltered"
                            :key="status.code"
                            class="flex items-center"
                            @click="changeStatus(status)">
                            <a-badge 
                                v-if="status.color !== 'default'" 
                                :color="status.color" />
                            {{ status.btn_title ? status.btn_title : status.name }}
                        </a-menu-item>
                    </template>
                    <a-menu-divider />
                </template>
                <a-menu-item 
                    v-if="dropActions.add_subtask && item.level < 3" 
                    key="add"
                    class="flex items-center"
                    @click="addSubtask()">
                    <i class="fi fi-rr-folder-tree mr-2"></i>
                    {{$t('task.add_subtask')}}
                </a-menu-item>
                <a-menu-item
                    v-if="dropActions.add_to_work_plan"
                    key="add_to_my_work_plan"
                    class="flex items-center"
                    @click="addToMyWorkPlan()">
                    <i class="fi fi-rr-calendar-plus mr-2"></i>
                    Добавить в план дня
                </a-menu-item>
                <a-menu-item 
                    v-if="dropActions.set_sprint"
                    key="add_to_sprint"
                    class="flex items-center"
                    @click="addToSprint()">
                    <i class="fi fi-rr-arrow-turn-down-right mr-2"></i>
                    Добавить в спринт
                </a-menu-item>
                <a-menu-item 
                    v-if="dropActions.unset_sprint"
                    key="delete_to_sprint"
                    class="flex items-center"
                    @click="removeToSprint()">
                    <i class="fi fi-rr-cross-circle mr-2"></i>
                    Убрать из спринта
                </a-menu-item>
                <a-menu-item
                    v-if="dropActions.share"
                    key="share"
                    class="flex items-center"
                    @click="share()">
                    <i class="fi fi-rr-share mr-2"></i>
                    {{$t('task.share_to_chat')}}
                </a-menu-item>
                <a-menu-item 
                    v-if="dropActions.edit" 
                    key="edit"
                    class="flex items-center"
                    @click="edit()">
                    <i class="fi fi-rr-edit mr-2"></i>
                    {{$t('task.edit')}}
                </a-menu-item>
                <a-menu-item
                    v-if="dropActions.copy"
                    key="copy"
                    class="flex items-center"
                    @click="copy()">
                    <i class="fi fi-rr-copy-alt mr-2"></i>
                    {{$t('task.copy')}}
                </a-menu-item>
                <template v-if="dropActions.delete && !item.children_count">
                    <a-menu-divider />
                    <a-menu-item
                        class="text-red-500 flex items-center" 
                        key="delete" 
                        @click="deleteTask()">
                        <i class="fi fi-rr-trash mr-2"></i>
                        {{$t('task.remove')}}
                    </a-menu-item>
                </template>
            </template>
        </a-menu>
    </a-dropdown>
</template>

<script>
import mixins from './mixins.js'
export default {
    mixins: [
        mixins
    ],
    computed: {
        // cStatusFiltered() {
        //     if(this.isAuthor  || (this.isLogistic && this.isOperator))
        //         return this.filteredList.filter(f => f.code !== this.item.status.code)
        //     return this.filteredList.filter(f => f.code !== this.item.status.code && !f.is_complete)
        // }

        changeStatusVisible() {
            return !this.item.is_auction && (this.dropActions.change_status || this.dropActions.change_cooperator_status) && this.showStatus && this.cStatusFiltered.length
        },
        cStatusFiltered() {
            const availableStatuses = this.dropActions?.change_status?.available_statuses
            if (availableStatuses) {
                return this.filteredList.filter(status => availableStatuses.includes(status.code))
            }

            const cooperator = this.item.cooperators.find(cooperator => cooperator.user.id === this.user.id)
            if (cooperator && !(this.isAuthor || this.isOperator)) {
                return this.filteredList.filter(f => f.code !== this.item.status.code)
            } 

            if(this.isAuthor  || (this.isLogistic && this.isOperator))
                return this.filteredList.filter(f => f.code !== this.item.status.code)
            return this.filteredList.filter(f => f.code !== this.item.status.code && !f.is_complete)
        },
    }
}
</script>