<template>
    <div class="chart_block donut_chart">
        <div class="chart_block__label">
            Задачи
        </div>
        <apexchart 
            type="donut" 
            :options="chartOptions" 
            class="w-full"
            :series="series" />
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
    components: {
        apexchart: VueApexCharts
    },
    props: {
        sprint: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            series: [
                this.sprint.new_task_count, 
                this.sprint.in_work_task_count, 
                this.sprint.completed_task_count, 
                this.sprint.overdue_task_count
            ],
            chartOptions: {
                responsive: [
                    {
                        breakpoint: 768,
                        options: {
                            chart: {
                                height: 280
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    },
                    {
                        breakpoint: 1400,
                        options: {
                            chart: {
                                height: 280
                            },
                            legend: {
                                position: 'right'
                            }
                        }
                    },
                    {
                        breakpoint: 9999,
                        options: {
                            chart: {
                                height: 280
                            },
                            legend: {
                                position: 'bottom'
                            }
                        }
                    }
                ],
                chart: {
                    type: 'donut'
                },
                stroke:{
                    colors: "#FAFAFA",
                    width: 2
                },
                legend: {
                    fontFamily: 'Roboto',
                    horizontalAlign: 'center', 
                    verticalAlign: 'center',
                    fontSize: '14px',
                    color: '#000',
                    formatter: function(seriesName, opts) {
                        return ["&#32;&#32;", seriesName, " - ", opts.w.globals.series[opts.seriesIndex]]
                    },
                    markers: {
                        width: 20,
                        height: 20,
                        radius: 4,
                        offsetY: 5
                    },
                    itemMargin: {
                        horizontal: 0,
                        vertical: 5
                    },
                    onItemHover: {
                        highlightDataSeries: true
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    expandOnClick:false,
                    pie: {
                        customScale: 1,
                        borderRadius: 10,
                        donut: {
                            size:'88%',
                            background: 'transparent',
                            labels: {
                                show: true,
                                name: {
                                    fontSize: '16px',
                                    color: '#000',
                                    fontFamily: 'Roboto',
                                    offsetY: 25
                                },
                                value: {
                                    show: true,
                                    fontSize: '36px',
                                    fontWeight: 300,
                                    color: '#000',
                                    fontFamily: 'Roboto',
                                    offsetY: -15,
                                    formatter: (value) => {
                                        return value
                                    },
                                },
                                total: {
                                    show: true,
                                    fontSize: '16px',
                                    fontWeight: 400,
                                    color: '#000',
                                    fontFamily: 'Roboto',
                                    label: 'Всего задач',
                                    formatter: () => this.sprint.task_count
                                }
                            }
                        }
                    },
                },
                colors: [
                    '#616cbd',
                    '#0f68c1',
                    '#56bcc8',
                    '#f37e5f'
                ],
                labels: [
                    'Новые', 
                    'В работе', 
                    'Завершено', 
                    'Просрочено'
                ]
            }
        }
    }
}
</script>