<template>
    <div>
        <div class="tab_selects">
            <div class="md:grid md:gap-4 md:grid-cols-4 tab_selects__wrap">
                <div v-for="tab in userTypeTab" :key="tab.value" class="tab_selects__wrap--item">
                    <a-button
                        block
                        size="large"
                        class="mr-2.5 last:mr-0 button-gray"
                        :class="activeUserTab === tab.value && 'active'"
                        @click="selectUserTab(tab.value)">
                        {{ tab.label }}
                    </a-button>
                </div>
            </div>
        </div>
        <!-- <div class="flex">
            <a-button
                v-for="tab in userTypeTab"
                :key="tab.value"
                size="large"
                class="mr-2.5 last:mr-0 button-gray"
                :class="activeUserTab === tab.value && 'active'"
                block
                @click="selectUserTab(tab.value)">
                {{ tab.label }}
            </a-button>
        </div> -->
        <div v-show="activeUserTab === 'operator'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="formInfo.operator"
                    :rules="formInfo.operator.rules"
                    :label="formInfo.operator.title"
                    prop="operator"
                    class="mb-0">
                    <div>
                        <UserDrawer
                            v-model="value.operator"
                            :taskId="value.id ? value.id : null"
                            :id="value.id || defaultUserSelectId"
                            inputSize="large"
                            class="w-full"
                            :disabled="value.is_auction"
                            :filters="
                                formInfo.operator.filters
                                    ? formInfo.operator.filters
                                    : null
                            "
                            :oldSelected="checkOldSelect(formInfo.operator)"
                            :title="
                                formInfo.operator.drawerTitle ||
                                    $t('task.select_performer')
                            "/>
                    </div>
                    <a-checkbox
                        v-if="formInfo.operator.auction"
                        v-model="value.is_auction"
                        @click="selectAuction()">
                        {{ $t("Activate auction") }}
                    </a-checkbox>
                </a-form-model-item>
            </div>
        </div>

        <div v-show="activeUserTab === 'owner'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="formInfo.owner"
                    :rules="formInfo.owner.rules"
                    :label="formInfo.owner.title"
                    class="mb-0"
                    prop="owner">
                    <UserDrawer
                        :id="value.id || defaultUserSelectId"
                        v-model="value.owner"
                        :disabled="edit"
                        :taskId="value.id ? value.id : null"
                        inputSize="large"
                        :title="
                            formInfo.owner.drawerTitle ||
                                $t('task.select_author')
                        "/>
                </a-form-model-item>
            </div>
        </div>

        <div v-show="activeUserTab === 'cooperators'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="formInfo.cooperators"
                    :rules="formInfo.cooperators.rules"
                    :label="formInfo.cooperators.title"
                    class="mb-0"
                    prop="visors">
                    <UserDrawer
                        :id="value.id || defaultUserSelectId"
                        :metadata="{ key: 'cooperators', value: value.metadata }"
                        :changeMetadata="changeMetadata"
                        v-model="value.cooperators"
                        :taskId="value.id ? value.id : null"
                        multiple
                        inputSize="large"
                        :title="
                            formInfo.cooperators.drawerTitle ||
                                $t('task.select_cooperators')
                        "/>
                </a-form-model-item>

            </div>
        </div>

        <div v-show="activeUserTab === 'visors'">
            <div class="pt-5">
                <a-form-model-item
                    v-if="formInfo.visors"
                    :rules="formInfo.visors.rules"
                    :label="formInfo.visors.title"
                    class="mb-0"
                    prop="visors">
                    <UserDrawer
                        :id="value.id || defaultUserSelectId"
                        :metadata="{ key: 'visors', value: value.metadata }"
                        :changeMetadata="changeMetadata"
                        v-model="value.visors"
                        :taskId="value.id ? value.id : null"
                        multiple
                        inputSize="large"
                        :title="
                            formInfo.visors.drawerTitle ||
                                $t('task.select_observers')
                        "/>
                </a-form-model-item>
            </div>
        </div>
    </div>
</template>

<script>
import UserDrawer from "@apps/DrawerSelect/index.vue"

export default {
    components: {
        UserDrawer
    },
    props: {
        value: { // form
            type: Object,
            required: true
        },
        formInfo: {
            type: Object,
            required: true
        },
        defaultUserSelectId: {
            type: String,
            default: 'empty_task'
        },
        edit: {
            type: Boolean,
            required: false
        },
        changeMetadata: {
            type: Function,
            required: true
        },
        checkOldSelect: {
            type: Function,
            required: true
        }
    },
    data() {
        return {
            activeUserTab: 'operator',
            userTypeTab: [
                {
                    value: "operator",
                    label: this.$t("Operator"),
                },
                {
                    value: "cooperators",
                    label: this.$t("Cooperator"),
                },
                {
                    value: "owner",
                    label: this.$t("Owner"),
                },
                {
                    value: "visors",
                    label: this.$t("Visors"),
                },
            ],
        }
    },
    methods: {
        selectUserTab(value) {
            this.activeUserTab = value;
        },
        selectAuction() {
            const form = {
                ...this.value,
                operator: null,
                is_auction: !this.value.is_auction
            }
            this.$emit('input', form)
        },
    }
}
</script>

<style lang="scss" scoped>
.tab_selects{
    @media (max-width: 767.98px) {
        margin-left: -15px;
        margin-right: -15px;
        &__wrap{
            display: -webkit-box;
            margin-bottom: 0;
            overflow-x: scroll;
            padding-left: 15px;
            padding-right: 15px;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            width: 100%;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: none;
            }
            &--item{
                margin-right: 10px;
            }
        }
    }
}
</style>