<template>
    <div>
        <EditDrawer />
        <TaskShowDrawer />
        <SprintShowDrawer />
        <CreateDrawer />
        <SprintEndDrawer />
        <AddTaskDrawer />
        <AddTaskListDrawer />
    </div>
</template>

<script>
import store from "./store/index"
import EditDrawer from './components/EditDrawer'
import TaskShowDrawer from './components/TaskShowDrawer'
import SprintShowDrawer from './components/Sprint/SprintShowDrawer/index.vue'
import CreateDrawer from './components/Sprint/CreateDrawer.vue'
import SprintEndDrawer from './components/Sprint/SprintEndDrawer.vue'
import AddTaskDrawer from './components/Sprint/AddTaskDrawer/index.vue'
import AddTaskListDrawer from './components/Sprint/AddTaskListDrawer.vue'
export default {
    components: {
        EditDrawer,
        TaskShowDrawer,
        SprintShowDrawer,
        CreateDrawer,
        SprintEndDrawer,
        AddTaskDrawer,
        AddTaskListDrawer
    },
    created() {
        if(!this.$store.hasModule('task')) {
            this.$store.registerModule("task", store)
            this.$store.dispatch('task/initFormInfo')
        }
    }
}
</script>