<template>
    <div>
        <div class="analytics_blocks mb-6 grid gap-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div class="analytics_blocks__item b_green">
                <div class="stat_value">{{ sprint.completed_task_count }}</div>
                <div class="stat_label">завершенные задачи</div>
            </div>
            <div class="analytics_blocks__item b_red">
                <div class="stat_value">{{ openetTasks }}</div>
                <div class="stat_label">открытые задачи</div>
            </div>
            <div class="analytics_blocks__item b_purple">
                <div class="stat_value">{{ sprint.wasted_time || 0 }}</div>
                <div class="stat_label">потрачено времени</div>
            </div>
            <!--<div class="analytics_blocks__item b_yellow">
                <div class="stat_value">5 000 000 тг</div>
                <div class="stat_label">смета</div>
            </div>-->
        </div>
        <div class="gap-3 graph_grid mb-6 chart_row">
            <LoadingResources 
                ref="loadingResources"
                :taskSetFilter="taskSetFilter"
                :sprint="sprint" />
            <Tasks ref="tasks" :sprint="sprint" />
            <!--<Estimate />-->
        </div>
        <!--<div class="mb-6 chart_row">
            <LoadingResources 
                ref="loadingResources"
                :sprint="sprint" />
        </div>-->
        <div>
            <a-button 
                v-if="userSelected" 
                class="mb-2"
                @click="taskSetFilter()">
                Сбросить фильтр
            </a-button>
            <component 
                ref="taskTable"
                :is="taskListWidget"
                :sprint="sprint"
                :model="model"
                :page_name="aPageName" />
        </div>
    </div>
</template>

<script>
import UniversalTable from '@/components/TableWidgets/UniversalTable'
import Tasks from './Charts/Tasks.vue'
//import Estimate from './Charts/Estimate.vue'
import LoadingResources from './Charts/LoadingResources.vue'
export default {
    components: {
        UniversalTable,
        Tasks,
        //Estimate,
        LoadingResources
    },
    props: {
        sprint: {
            type: Object,
            required: true
        },
        actions: {
            type: Object,
            required: true
        },
        page_name: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            model: 'tasks.TaskModel',
            userSelected: null
        }
    },
    computed: {
        taskListWidget() {
            if(this.isMobile)
                return () => import('./TaskList.vue')
            else    
                return () => import('./TaskTable.vue')
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        openetTasks() {
            return this.sprint.in_work_task_count+this.sprint.new_task_count
        },
        aPageName() {
            return `analytics_${this.page_name}`
        }
    },
    methods: {
        taskSetFilter(item = null) {
            const author = item?.customData?.author || null
            if (this.userSelected === author) {
                this.userSelected = null
                this.$nextTick(() => this.$refs.taskTable.clearTaskFilter())
            } else {
                this.userSelected = author
                this.$nextTick(() => {
                    if (author) {
                        this.$refs.taskTable.taskSetFilter(item)
                    } else {
                        this.$refs.taskTable.clearTaskFilter()
                    }
                })
            }
        },
        analyticsUpdate() {
            this.$nextTick(() => {
                if(this.$refs.loadingResources)
                    this.$refs.loadingResources.getStat()
                if(this.$refs.taskTable)
                    this.$refs.taskTable.reloadTableData()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.graph_grid{
    @media (max-width: 1399.98px) {
        &::v-deep{
            .chart_block{
                &:not(:last-child){
                    margin-bottom: 0.75rem;
                }
            }
        }
    }
    @media (min-width: 1400px) {
        display: grid;
        grid-template-columns: 1fr 400px;
    }
}
.chart_row{
    &::v-deep{
        .chart_block{
            background: #FAFAFA;
            border-radius: 12px;
            padding: 15px;
            &__label{
                color: #000;
                font-weight: 400;
                font-size: 18px;
                line-height: 18px;
                margin-bottom: 10px;
            }
            &__header{
                margin-bottom: 10px;
                @media (min-width: 768px) {
                    display: flex;
                    align-items: center;
                }
                &--label{
                    color: #000;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 18px;
                    @media (max-width: 767.98px) {
                        &:not(:last-child){
                            margin-bottom: 8px;
                        }
                    }
                }
            }
            &.donut_chart{
                .apexcharts-legend{
                    .apexcharts-legend-series{
                        display: flex;
                        align-items: center;
                        &:not(:last-child){
                            margin-right: 10px!important;
                        }
                        .apexcharts-legend-marker{
                            top: 0px!important;
                        }
                        .apexcharts-legend-text{
                            padding-left: 20px;
                        }
                    }
                }
            }
        }
    }
}
.analytics_blocks{
    &__item{
        padding: 25px 15px;
        border-radius: 8px;
        color: #000;
        display: flex;
        flex-direction: column;
        .stat_value{
            font-weight: 400;
            font-size: 24px;
            line-height: 24px;
            margin-bottom: 8px;
            flex-grow: 1;
        }
        .stat_label{
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
        }
        &.b_green{
            background: #bff3d1;
        }
        &.b_red{
            background: #f3c0c0;
        }
        &.b_purple{
            background: #c1c8fd;
        }
        &.b_yellow{
            background: #f0fdbd;
        }
    }
}
</style>