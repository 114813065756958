var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"tab_selects"},[_c('div',{staticClass:"md:grid md:gap-4 md:grid-cols-4 tab_selects__wrap"},_vm._l((_vm.userTypeTab),function(tab){return _c('div',{key:tab.value,staticClass:"tab_selects__wrap--item"},[_c('a-button',{staticClass:"mr-2.5 last:mr-0 button-gray",class:_vm.activeUserTab === tab.value && 'active',attrs:{"block":"","size":"large"},on:{"click":function($event){return _vm.selectUserTab(tab.value)}}},[_vm._v(" "+_vm._s(tab.label)+" ")])],1)}),0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeUserTab === 'operator'),expression:"activeUserTab === 'operator'"}]},[_c('div',{staticClass:"pt-5"},[(_vm.formInfo.operator)?_c('a-form-model-item',{staticClass:"mb-0",attrs:{"rules":_vm.formInfo.operator.rules,"label":_vm.formInfo.operator.title,"prop":"operator"}},[_c('div',[_c('UserDrawer',{staticClass:"w-full",attrs:{"taskId":_vm.value.id ? _vm.value.id : null,"id":_vm.value.id || _vm.defaultUserSelectId,"inputSize":"large","disabled":_vm.value.is_auction,"filters":_vm.formInfo.operator.filters
                                ? _vm.formInfo.operator.filters
                                : null,"oldSelected":_vm.checkOldSelect(_vm.formInfo.operator),"title":_vm.formInfo.operator.drawerTitle ||
                                _vm.$t('task.select_performer')},model:{value:(_vm.value.operator),callback:function ($$v) {_vm.$set(_vm.value, "operator", $$v)},expression:"value.operator"}})],1),(_vm.formInfo.operator.auction)?_c('a-checkbox',{on:{"click":function($event){return _vm.selectAuction()}},model:{value:(_vm.value.is_auction),callback:function ($$v) {_vm.$set(_vm.value, "is_auction", $$v)},expression:"value.is_auction"}},[_vm._v(" "+_vm._s(_vm.$t("Activate auction"))+" ")]):_vm._e()],1):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeUserTab === 'owner'),expression:"activeUserTab === 'owner'"}]},[_c('div',{staticClass:"pt-5"},[(_vm.formInfo.owner)?_c('a-form-model-item',{staticClass:"mb-0",attrs:{"rules":_vm.formInfo.owner.rules,"label":_vm.formInfo.owner.title,"prop":"owner"}},[_c('UserDrawer',{attrs:{"id":_vm.value.id || _vm.defaultUserSelectId,"disabled":_vm.edit,"taskId":_vm.value.id ? _vm.value.id : null,"inputSize":"large","title":_vm.formInfo.owner.drawerTitle ||
                            _vm.$t('task.select_author')},model:{value:(_vm.value.owner),callback:function ($$v) {_vm.$set(_vm.value, "owner", $$v)},expression:"value.owner"}})],1):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeUserTab === 'cooperators'),expression:"activeUserTab === 'cooperators'"}]},[_c('div',{staticClass:"pt-5"},[(_vm.formInfo.cooperators)?_c('a-form-model-item',{staticClass:"mb-0",attrs:{"rules":_vm.formInfo.cooperators.rules,"label":_vm.formInfo.cooperators.title,"prop":"visors"}},[_c('UserDrawer',{attrs:{"id":_vm.value.id || _vm.defaultUserSelectId,"metadata":{ key: 'cooperators', value: _vm.value.metadata },"changeMetadata":_vm.changeMetadata,"taskId":_vm.value.id ? _vm.value.id : null,"multiple":"","inputSize":"large","title":_vm.formInfo.cooperators.drawerTitle ||
                            _vm.$t('task.select_cooperators')},model:{value:(_vm.value.cooperators),callback:function ($$v) {_vm.$set(_vm.value, "cooperators", $$v)},expression:"value.cooperators"}})],1):_vm._e()],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeUserTab === 'visors'),expression:"activeUserTab === 'visors'"}]},[_c('div',{staticClass:"pt-5"},[(_vm.formInfo.visors)?_c('a-form-model-item',{staticClass:"mb-0",attrs:{"rules":_vm.formInfo.visors.rules,"label":_vm.formInfo.visors.title,"prop":"visors"}},[_c('UserDrawer',{attrs:{"id":_vm.value.id || _vm.defaultUserSelectId,"metadata":{ key: 'visors', value: _vm.value.metadata },"changeMetadata":_vm.changeMetadata,"taskId":_vm.value.id ? _vm.value.id : null,"multiple":"","inputSize":"large","title":_vm.formInfo.visors.drawerTitle ||
                            _vm.$t('task.select_observers')},model:{value:(_vm.value.visors),callback:function ($$v) {_vm.$set(_vm.value, "visors", $$v)},expression:"value.visors"}})],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }