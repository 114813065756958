import { i18n } from '@/config/i18n-setup'

export const priorityList = [
    {
        name: i18n.t('task.ultralow'),
        value: 0,
        color: 'success',
        i18n: 'ultralow'
    },
    {
        name: i18n.t('task.low'),
        value: 1,
        color: 'success',
        i18n: 'low'
    },
    {
        name: i18n.t('task.middle'),
        value: 2,
        color: 'warning',
        i18n: 'middle'
    },
    {
        name: i18n.t('task.tall'),
        value: 3,
        color: 'error',
        i18n: 'tall'
    },
    {
        name: i18n.t('task.veryhigh'),
        value: 4,
        color: 'error',
        i18n: 'veryhigh'
    }
]

export const replaceFlatArray = (array) => {
    let newArray = []
    array.forEach(item => {
        newArray.push(item)
        if (item.children && item.children.length) {
            newArray = newArray.concat(replaceFlatArray(item.children))
        }
    })
    return newArray
}
