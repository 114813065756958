<template>
    <div ref="task_tab" class="task_tab">
        <div v-if="!isMobile" class="flex items-center pb-3 task_tabs">
            <a-button :type="active === 1 ? 'primary' : 'default'" size="large" class="mr-2 px-5" @click="setActive(1)">
                Список
            </a-button>
            <a-button :type="active === 2 ? 'primary' : 'default'" size="large" class="px-5" @click="setActive(2)">
                Канбан
            </a-button>
        </div>
        <div v-if="!isMobile" class="flex items-center justify-between pb-4 task_head">
            <PageFilter 
                :model="model"
                :key="page_name"
                size="large"
                :excludeFields="excludeFields"
                :getPopupContainer="getPopupContainer"
                :page_name="page_name" />
            <div class="flex items-center">
                <SettingsButton
                    v-if="active === 1"
                    :pageName="page_name"
                    :zIndex="1200" />
                <a-button 
                    v-if="actions && actions.set_task && actions.set_task.availability && sprint.status !== 'completed'"
                    type="primary"
                    size="large"
                    flaticon
                    class="ml-2"
                    icon="fi-rr-plus"
                    @click="addTask()">
                    Добавить задачи
                </a-button>
            </div>
        </div>
        <template v-if="active === 1">
            <component 
                :is="taskComponent"
                ref="taskListWidget"
                :sprint="sprint"
                :model="model"
                :actions="actions"
                :page_name="page_name" />
            <div 
                v-if="isMobile"
                class="float_add">
                <div class="filter_slot">
                    <PageFilter 
                        :model="model"
                        :key="page_name"
                        size="large"
                        :excludeFields="excludeFields"
                        :getPopupContainer="getPopupContainer"
                        :page_name="page_name" />
                </div>
                <a-button 
                    v-if="actions && actions.set_task && actions.set_task.availability && sprint.status !== 'completed'"
                    flaticon
                    shape="circle"
                    size="large"
                    type="primary"
                    icon="fi-rr-plus"
                    @click="addTask()"  />
            </div>
        </template>
        <div v-if="active === 2" class="sprint_kanban_wrapp">
            <Kanban 
                :implementId="sprint.id"
                :formParams="formParams"
                :showAddButton="false"
                :extendDrawer="true"
                taskType="task"
                :queryParams="queryParams"
                implementType="sprint">
            </Kanban>
        </div>
    </div>
</template>

<script>
import PageFilter from '@/components/PageFilter'
import SettingsButton from '@/components/TableWidgets/SettingsButton'
import Kanban from '@apps/vue2TaskComponent/components/Kanban'
import eventBus from '@/utils/eventBus'
export default {
    components: {
        PageFilter,
        SettingsButton,
        Kanban
    },
    props: {
        sprint: {
            type: Object,
            required: true
        },
        actions: {
            type: Object,
            required: true
        },
        page_name: {
            type: String,
            required: true
        },
        changeActive: {
            type: Function,
            default: () => {}
        }
    },
    data() {
        return {
            model: 'tasks.TaskModel',
            active: 1,
            formParams: {},
            queryParams: {
                page_name: this.page_name
            }
        }
    },
    computed: {
        excludeFields() {
            if(this.active === 1)
                return ['sprint__exclude', 'sprint']
            else
                return ['sprint__exclude', 'sprint', 'status__exclude', 'status']
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        taskComponent() {
            if(this.isMobile)
                return () => import('./TaskList.vue')
            else
                return () => import('./TaskTable.vue')
        }
    },
    methods: {
        setActive(active) {
            this.active = active
            this.changeActive(active)
        },
        addTask() {
            eventBus.$emit('sprint_add_task', this.sprint.id)
        },
        getPopupContainer() {
            return this.$refs.task_tab
        },
        tableReload() {
            if(this.active === 2)
                eventBus.$emit(`RELOAD_COLUMN_FROM_${this.sprint.id}`)
            this.$nextTick(() => {
                if(this.$refs.taskListWidget)
                    this.$refs.taskListWidget.tableReload()
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.sprint_kanban_wrapp{
    margin-left: -30px;
    margin-right: -30px;
}
</style>