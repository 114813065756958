<template>
    <div>
        <a-form-model-item
            v-if="formInfo.organization"
            :rules="formInfo.organization.rules"
            :label="formInfo.organization.title"
            prop="organization">
            <OrganizationsDrawer
                v-model="value.organization"
                :defaultActiveFirstOption="!value.organization && !edit"
                :title="
                    formInfo.organization.drawerTitle ||
                        $t('task.select_organization')
                "
                inputSize="large"/>
        </a-form-model-item>

        <a-form-model-item
            v-if="formInfo.project"
            :rules="formInfo.project.rules"
            :label="formInfo.project.title"
            prop="project">
            <ProjectDrawer
                v-model="value.project"
                :selectProject="selectProject"
                :title="
                    formInfo.project.drawerTitle ||
                        $t('task.select_project')
                "
                inputSize="large"/>
        </a-form-model-item>

        <a-form-model-item
            v-if="formInfo.parent"
            :rules="formInfo.parent.rules"
            :label="formInfo.parent.title"
            prop="parent">
            <div
                class="popover_input ant-input flex items-center relative ant-input-lg truncate"
                :class="changeParentDisabled && 'ant-input-disabled'">
                <a-tooltip
                    v-if="value.parent"
                    :title="value.parent.name"
                    class="mr-2 truncate">
                    <a-tag
                        color="blue"
                        class="tag_block truncate"
                        @click="openSubtaskSelection">
                        {{ value.parent.name }}
                    </a-tag>
                </a-tooltip>
                <a-button
                    @click="openSubtaskSelection"
                    type="link"
                    :icon="!value.parent && 'plus'"
                    class="px-0">
                    {{
                        value.parent ? $t("task.change") : $t("task.select")
                    }}
                </a-button>
                <a-button
                    v-if="value.parent"
                    @click="selectParentTask()"
                    type="link"
                    icon="close-circle"
                    class="px-0 text-current remove_parent"/>
            </div>
        </a-form-model-item>

        <a-form-model-item
            v-if="formInfo.workgroup"
            :rules="formInfo.workgroup.rules"
            :label="formInfo.workgroup.title"
            class="mb-0"
            prop="workgroup">
            <WorkGroupDrawer
                :title="
                    formInfo.workgroup.drawerTitle ||
                        $t('task.subject_matter')
                "
                v-model="value.workgroup"
                inputSize="large"/>
        </a-form-model-item>
    </div>
</template>

<script>
import ProjectDrawer from "../../ProjectDrawer.vue";
import OrganizationsDrawer from "../../OrganizationsDrawer.vue";
import WorkGroupDrawer from "../../WorkGroupDrawer.vue";


export default {
    components: { 
        ProjectDrawer, 
        OrganizationsDrawer,
        WorkGroupDrawer,
    },
    props: {
        value: { // form
            type: Object,
            required: true
        },
        formInfo: {
            type: Object,
            required: true
        },
        edit: {
            type: Boolean,
            required: true
        },
        selectProject: {
            type: Function,
            required: true
        },
        openSubtaskSelection: {
            type: Function,
            required: true
        },
        selectParentTask: {
            type: Function,
            required: true
        },
        changeParentDisabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>